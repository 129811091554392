<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Event Manage</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-1">
          <p-button class="no-margin" type="default" block outline round  @click="openAddModal()"> <i class="fa fa-plus"></i> </p-button>
        </div>
        <div class="col-sm-5"  v-if="pagination">
          <el-select
            class="select-default"
            v-model="pagination.per_page"
            placeholder="Per page"
            @change="changePerPage()"
          >
            <el-option
              class="select-default"
              v-for="item in perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="Search"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            ></fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%;"
            height="600"
          >
            <el-table-column
              v-for="(column) in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :sortable="column.sortable || false"
              :align="column.align"
              :header-align="column.align"
              v-if="!column.hidden"
              :fixed="column.fixed || false"
              :formatter="column.formatter || null"
            ></el-table-column>

            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">
                <!-- <p-button type="info" size="sm" icon @click="handleLike(props.$index, props.row)">
                  <i class="fa fa-eye"></i>
                </p-button> -->
                <p-button
                  type="success"
                  size="sm"
                  icon
                  @click="handleEdit(props.$index, props.row)"
                >
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button
                  type="danger"
                  size="sm"
                  icon
                  @click="handleDelete(props.$index, props.row)"
                >
                  <i class="fa fa-trash"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info"  v-if="pagination">
          <p
            class="category"
          >Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries</p>
        </div>
        <div class="col-sm-6"  v-if="pagination">
          <p-pagination
            class="pull-right"
            v-model="pagination.current_page"
            :per-page="Number.parseInt(pagination.per_page)"
            :total="pagination.total"
            @input="changePage()"
          ></p-pagination>
        </div>
      </div>
    </div>

    <modal
      :show.sync="modals.add"
      headerClasses="justify-content-center"
      footer-classes="justify-content-center"
      >
      <h5 slot="header" class="modal-title">Manage Event</h5>
      <form>
        <div class="row">

          <div class="form-group col-12">
            <label for>Title</label>
            <fg-input
              name="title"
              v-model="model.title"
              v-validate="modelValidations.title"
              :error="getError('title')"
            ></fg-input>
          </div>

          <div class="form-group col-12">
            <label for>Description</label>
            <fg-input
              name="desc"
              v-model="model.desc"
              v-validate="modelValidations.desc"
              :error="getError('desc')"
            ></fg-input>
          </div>

          <div class="form-group col-12">
            <label for>Start Datetime</label>
            <fg-input
              name="start_datetime"
              v-model="model.start_datetime"
              v-validate="modelValidations.start_datetime"
              :error="getError('start_datetime')"
            >
              <!-- <el-date-picker v-model="model.date" type="date" placeholder="Pick a day" :picker-options="datePickerOptions"></el-date-picker> -->
              <el-date-picker v-model="model.start_datetime" type="datetime" placeholder="Select start date and time"></el-date-picker>
            </fg-input>
          </div>

          <div class="form-group col-12">
            <label for>End Datetime</label>
            <fg-input
              name="end_datetime"
              v-model="model.end_datetime"
              v-validate="modelValidations.end_datetime"
              :error="getError('end_datetime')"
            >
              <!-- <el-date-picker v-model="model.date" type="date" placeholder="Pick a day" :picker-options="datePickerOptions"></el-date-picker> -->
              <el-date-picker v-model="model.end_datetime" type="datetime" placeholder="Select end date and time"></el-date-picker>
            </fg-input>
          </div>

          <div class="form-group col-12">
            <label for>Employee</label>
            <fg-input
              name="user_id"
              v-model="model.user_id"
              v-validate="modelValidations.user_id"
              :error="getError('user_id')"
            >
              <el-select
                name="user_id"
                :class="!getError('user_id') ? 'select-default' : 'select-danger'"
                class="select-default"
                placeholder="Select Employee"
                v-model="model.user_id"
                filterable
              >
                <el-option
                  v-for="(option, index) in employees"
                  class="select-success"
                  :value="option.id"
                  :label="`${option.employee_id} - ${option.name}`"
                  :key="index"
                ></el-option>
              </el-select>
            </fg-input>
          </div>

          


        </div>
      </form>
      <template slot="footer">
        <p-button type="success" @click="validate()">Save</p-button>
      </template>
    </modal>


  </div>
</template>


<script>
import Vue from "vue";
import { Modal } from "src/components/UIComponents";
import { Table, TableColumn, Select, Option, Slider, DatePicker, TimeSelect } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
// import users from "../Tables/users.js";
import swal from 'sweetalert2'
import { mapGetters } from "vuex";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Slider);

export default {
  components: {
    [TimeSelect.name]: TimeSelect,
    [DatePicker.name]: DatePicker,
    PPagination,
    Modal
  },
  mounted() {
    this.$store.dispatch("fetchEvents", { per_page: 10, page: 1 });
    this.$store.dispatch("fetchEmployeesAll");

    if(this.$route.params.event_id){
      this.$store.dispatch("fetchEvent", this.$route.params.event_id);
    }

  },
  computed: {
    ...mapGetters({
      tableData: "getEvents",
      pagination: "getPagination",
      employees: "getEmployees",
      event: "getEvent"
    }),
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      // if (!this.searchQuery) {
      //   if(this.locations)
      //   this.pagination.total = this.locations.length;
      //   else
      //   this.pagination.total = 0
      //   return this.pagedData;
      // }
      // let result = this.locations.filter(row => {
      //   let isIncluded = false;
      //   for (let key of this.propsToSearch) {
      //     let rowValue = row[key].toString();
      //     if (rowValue.includes && rowValue.includes(this.searchQuery)) {
      //       isIncluded = true;
      //     }
      //   }
      //   return isIncluded;
      // });
      // this.pagination.total = result.length;
      // return result.slice(this.from, this.to);
    }
  },
  watch: {
    // whenever question changes, this function will run
    event: function (newEvent, oldEvent) {
      this.model.id = newEvent.id;
      this.model.title = newEvent.title;
      this.model.desc = newEvent.desc;
      this.model.start_datetime = newEvent.start_datetime;
      this.model.end_datetime = newEvent.end_datetime;
      this.model.user_id = newEvent.user_id;
      this.scope = 'edit';
      this.modals.add = true;
    },
      searchQuery: function (val) {
          this.$store.dispatch("fetchEvents", {
              searchQuery: this.searchQuery,
              per_page: this.pagination.per_page,
              page: this.pagination.current_page
          }); 
      }
  },
  data() {
    return {
      modals: {
        add: false,
      },
      scope: 'add',
      datePickerOptions: {
        shortcuts: [{
          text: 'Today',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        },
        {
          text: 'Tomorrow',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 1)
            picker.$emit('pick', date)
          }
        },
        {
          text: 'Next week',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      perPageOptions: ["10", "30", "50", "100", "250", "500"],
      searchQuery: "",
      propsToSearch: [
        "title",
        "desc",
        "start_datetime",
        "end_datetime",
      ],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          minWidth: 50,
          hidden: true,
          align: "center"
        },
        {
          prop: "title",
          label: "Title",
          minWidth: 200,
          fixed: false,
          sortable: true,
          align: "left"
        },
        {
          prop: "desc",
          label: "Description",
          minWidth: 250,
          fixed: false,
          sortable: true,
          align: "left"
        },
        {
          prop: "start_datetime",
          label: "Start Date & Time",
          minWidth: 350,
          align: "center",
          formatter: function(row, column, cellValue, index){
            var d = new Date(row.start_datetime);
            var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
            return  d.toLocaleDateString('en-US', options); 
          }
        },
        {
          prop: "end_datetime",
          label: "End Date & Time",
          minWidth: 350,
          align: "center",
          formatter: function(row, column, cellValue, index){
            var d = new Date(row.end_datetime);
            var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
            return  d.toLocaleDateString('en-US', options); 
          }
        },
        {
          prop: "employee.employee_id",
          label: "Employee ID",
          minWidth: 150,
          align: "center"
        },
        {
          prop: "employee.name",
          label: "Employee Name",
          minWidth: 200,
          align: "left"
        }
      ],
      model: {
        id: null,
        title: null,
        desc: null,
        start_datetime: null,
        end_datetime: null,
        user_id: null,
      },
      modelValidations: {
        title: {
          required: true
        },
        desc: {
          required: false
        },
        start_datetime: {
          required: true
        },
        end_datetime: {
          required: true
        },
        user_id: {
          required: true
        }
      }
      // tableData: this.locations
    };
  },
  methods: {
    changePerPage() {
      this.$store.dispatch("fetchEvents", {
        per_page: this.pagination.per_page,
        page: 1, searchQuery : this.searchQuery
      });
    },
    changePage() {
      this.$store.dispatch("fetchEvents", {
        per_page: this.pagination.per_page,
        page: this.pagination.current_page, searchQuery : this.searchQuery
      });
    },
    handleEdit(index, row) {
      // this.$router.push(`/products/edit/${row.id}`);
      this.model.id = row.id;
      this.model.title = row.title;
      this.model.desc = row.desc;
      this.model.start_datetime = row.start_datetime;
      this.model.end_datetime = row.end_datetime;
      this.model.user_id = row.user_id;
      this.scope = 'edit';
      this.modals.add = true;
      // this.$store.dispatch("createEvent", this.model);
    },
    handleDelete(index, row) {
      // this.$store.dispatch("deleteEvent", row.id);
      swal({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonClass: "btn btn-danger btn-fill",
          cancelButtonClass: "btn btn-default btn-fill",
          confirmButtonText: "Delete it!",
          buttonsStyling: false
      })
      .then(result => { this.$store.dispatch("deleteEvent", row.id); })
      .catch(cancel => { console.log(cancel) });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.modals.add = false;
          switch(this.scope){
            case 'add'  : this.$store.dispatch("createEvent", this.model); break;
            case 'edit' : this.$store.dispatch("updateEvent", this.model); break;
          }
        }
      });      
    },
    openAddModal(){
      this.model.id = null;
      this.model.title = null;
      this.model.desc = null;
      this.model.start_datetime = null;
      this.model.end_datetime = null;
      this.model.user_id = null;
      this.scope = 'add';
      this.modals.add = true;
    },
  }
};
</script>

<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}

button.no-margin{
  margin: 0;
}

.el-table,
.el-table tr,
.el-table thead th {
  background-color: white !important;
}

.form-group .el-input-number {
  width: 35%;
  outline: none;
}
.el-input--small .el-input__inner {
  border-radius: 50px;
}
</style>
